<template>
  <div class="home">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <box></box>
    <Sixbox></Sixbox>
    <four-boxa></four-boxa> -->
    <!-- 平台 -->
    <div class="platform" >
      <img class="ambassadorImg" :src="ambassadorImg" alt="" ondragstart="return false;" oncontextmenu="return false;" />
      <div class="professor">专家风采  <span class="backList" style="float:right;" @click="back()">返回列表</span></div>
      <div class="box" v-for="item in mess" :key="item">
        <div class="main">
          <img class="img" :src="item.image" alt="" />
          <div class="right">
            <div class="a">
              <p class="name">{{ item.name }}</p>
              <!-- <p class="active">活跃度:  <span style="color:#ea8772">{{ item.name }}</span> </p> -->
            </div>
            <div class="b">
              {{ item.service_province }}{{ item.service_city }}
            </div>
            <div class="c">
              <span class="type" v-for="item in item.item" :key="item"> {{item}}</span>
            </div>
          </div>
        </div>

        <div class="detail" v-html="item.content"></div>
      </div>
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent,reactive} from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/utils/axios";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";
import { ref } from "vue";
import * as echarts from "echarts";
import "../../../node_modules/echarts/map/china.js"; // 引入中国地图数据
export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      ambassadorImg: require("@/assets/1new/赋能工程/赋能大使.jpg"),
      list: [
        {
          img: require("@/assets/1new/赋能工程/4.jpg"),
          name: "专家一",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
      ],
    };
  },
  methods: {
    back(){
this.$router.go(-1)
    },
  },
  setup(){
     var mess = reactive({ data: "" });
    const route = useRoute();
    axios
      .get(
        "https://www.xsy985.com/api/expert/expert/" + route.params.id,
      )
      .then(function (response) {
        console.log('11111',response.data);
        mess.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      mess,
    };
  }
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   // min-height: 200px;
//   background-color:skyblue;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   display: block;
// }
.backList{
  margin-right: 100px;
}
.platform {
  width: 1200px;
  padding-left: 10px;
  min-height: 300px;
  background-color: #fff;
  .ambassadorImg {
    width: 1200px;
    height: 100px;
  }
  .professor {
    border-left: 5px solid #00a5a8;
    height: 31px;
    font-size: 24px;
    padding-left: 11px;
    margin-bottom: 25px;
  }
  .box {
    .main {
      width: 100%;
      margin-bottom: 40px;
      height: 94px;
      .img {
        float: left;
        width:120px;
        height: 120px;
        border: 4px solid #00a5a8;
        border-radius: 50%;
      }
      .right {
        width: 300px;
        height: 94px;
        float: left;
        margin-left: 28px;
        .a{
          display: block;
          margin-bottom: 14px;
          margin-top: 16px;
          .name{
            float: left;
            font-size: 20px;
            margin-right: 20px;
          }
          .active{
            font-size: 14px;
            line-height: 20px;
            color: #999;
          }
        }
        .b{
          margin-bottom: 8px;
          font-size: 16px;
display: block;
        }
        .c{
display: block;
.type{
  width: 62px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 4px;
  margin-right: 10px;
}

        }
      }
    }
    .detail {
      font-size: 14px;
      line-height: 20px;
      color: #999;
    }
  }
}

.message {
  width: 1200px;
  // background-color: skyblue;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
</style>
